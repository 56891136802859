<template>
  <div class="container mt-4">
    <h2>Tour List</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Tour Code</th>
          <th>Tour Name</th>
          <th>Cruise Line</th>
          <th>Year</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tourGroup in groupedTours" :key="tourGroup.key">
          <td>{{ tourGroup.tourCode }}</td>
          <td>{{ tourGroup.tourName }}</td>
          <td>{{ tourGroup.cruiseLine }}</td>
          <td>{{ tourGroup.years.join(', ') }}</td>
          <td>
            <button class="btn btn-primary" @click="openTourDetails(tourGroup)">View Details</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Tours',
  data() {
    return {
      tours: [],
      groupedTours: [],
      loading: true
    };
  },
  mounted() {
    this.fetchTours();
  },
  methods: {
    async fetchTours() {
      try {
        const response = await this.$xhr.get('/api/tours?populate=*');
        this.tours = response.data.data.map(tour => ({
          ...tour,
          attributes: {
            ...tour.attributes,
            tiers: tour.attributes.tiers || [],
            item: tour.attributes.item || [] // Ensure items is an array
          }
        }));
        this.groupTours();
      } catch (error) {
        console.error("Failed to fetch tours:", error);
      } finally {
        this.loading = false;
      }
    },
    groupTours() {
      const groups = {};
      this.tours.forEach((tour) => {
        const { tourCode, cruiseLine, tourName, year } = tour.attributes;
        const key = `${tourCode}-${cruiseLine}-${tourName}`;

        if (!groups[key]) {
          groups[key] = { tourCode, cruiseLine, tourName, years: [] };
        }
        groups[key].years.push(year);
      });

      this.groupedTours = Object.values(groups);
    },
    openTourDetails(tourGroup) {
      this.$router.push({ name: 'Tourstats', params: { tourCode: tourGroup.tourCode } });
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
</style>
