<template>
    <div class="container mt-4">
        <Pre></Pre>
        <h2 style="margin-top:50px ;">{{ tourName }} -Sell Price Data by Year and Tier</h2>

        <!-- Bar Chart for Price Data -->
        <canvas id="salesChart"></canvas>
        <h3>Percentage Change in Sell Price</h3>

        <!-- Pie Charts for Percentage Change per Tier in One Row -->
        <div class="pie-charts-container mt-5">
            <div v-for="(tierValue, tierIndex) in tiers" :key="tierValue" class="pie-chart-item">
                <canvas :id="'percentageChart_' + tierValue"></canvas>
                <h3>Tier{{ tierValue }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
    name: 'Tourstats',
    data() {
        return {
            tourName: '',
            salesData: [],
            years: [],
            tiers: [],
            sellingData: [],
            percentageData: [],
        };
    },
    mounted() {
        this.fetchTourData();
    },
    methods: {
        async fetchTourData() {
            try {
                const response = await this.$xhr.get('/api/tours?populate=*');
                const tours = response.data.data.filter(
                    (tour) => tour.attributes.tourCode === this.$route.params.tourCode
                );
                console.log(tours); // Log to verify multiple entries

                if (tours.length > 0) {
                    // Assume the tour name is the same for all entries
                    this.tourName = tours[0].attributes.tourName;

                    // Collect unique years across all entries from the tour object
                    this.years = [
                        ...new Set(
                            tours.map((tour) => tour.attributes.year) // Get year from tour directly
                        ),
                    ];

                    // Sort the years array in ascending order
                    this.years.sort((a, b) => a - b);
                    console.log(this.years);

                    // Collect unique tiers across all entries
                    this.tiers = [
                        ...new Set(
                            tours.flatMap((tour) =>
                                tour.attributes.tiers.map((tier) => tier.value)
                            )
                        ),
                    ];

                    // Prepare selling data for each year and tier
                    this.prepareSellingData(tours);

                    // Calculate percentage change data for consecutive years
                    this.calculatePercentageChange();

                    // Ensure the DOM is updated before creating the charts
                    this.$nextTick(() => {
                        // Create the bar chart for price data
                        this.createChart();
                        // Create Pie Charts for each tier showing percentage change
                        this.createPercentageCharts();
                    });
                }
            } catch (error) {
                console.error('Error fetching tour data:', error);
            }
        },

        prepareSellingData(tours) {
            // Initialize sellingData array to store prices for each year and tier
            this.sellingData = this.years.map((year) => {
                return this.tiers.map((tierValue) => {
                    let sellingPrice = 0;

                    // Loop through each tour entry to find prices for the specified year and tier
                    tours.forEach((tour) => {
                        if (tour.attributes.year === year) { // Ensure the year matches the tour's year
                            const tier = tour.attributes.tiers.find((t) => t.value === tierValue);
                            if (tier && tier.userInputSellingPriceAdult) {
                                sellingPrice += tier.userInputSellingPriceAdult; // Accumulate price if multiple entries have it
                            }
                        }
                    });

                    return sellingPrice || 0; // Return 0 if no selling price is found
                });
            });
        },

        calculatePercentageChange() {
            // Initialize percentageData array for each consecutive year pair
            this.percentageData = [];

            // Loop through all tiers
            this.tiers.forEach((tierValue, tierIndex) => {
                let percentageChangesForTier = [];

                // Loop through years and calculate the percentage change between consecutive years
                for (let i = 0; i < this.years.length - 1; i++) {
                    const previousYear = this.years[i];
                    const currentYear = this.years[i + 1];

                    const previousPrice = this.sellingData[i][tierIndex];
                    const currentPrice = this.sellingData[i + 1][tierIndex];

                    // Only calculate percentage if the previous price is greater than 0
                    const percentageChange = previousPrice > 0
                        ? ((currentPrice - previousPrice) / previousPrice) * 100
                        : 0;

                    percentageChangesForTier.push(percentageChange);
                }

                // Store the percentage changes for this tier
                this.percentageData.push(percentageChangesForTier);
            });
        },

        createChart() {
            // Bar chart data and options for price data
            new Chart(document.getElementById('salesChart'), {
                type: 'bar',
                data: {
                    labels: this.tiers, // X-axis is the tiers
                    datasets: this.years.map((year, index) => ({
                        label: `${year}`,
                        data: this.sellingData[index], // Use the selling data for the current year
                        backgroundColor: `rgba(${index * 50}, 99, 132, 0.6)`, // Different colors for each year
                        borderColor: `rgba(${index * 50}, 99, 132, 1)`,
                        borderWidth: 1,
                        // Adjust bar thickness and spacing
                        barThickness: 40,
                        categoryPercentage: 0.5, // Control the spacing between bars
                        barPercentage: 0.8, // Adjust the width of the bars
                    })),
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Sell Price Adult', // Label for Y-axis
                            },
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Tiers', // Label for X-axis
                            },
                            // Adjust the ticks to display them properly
                            ticks: {
                                autoSkip: false,
                            },
                        },
                    },
                },
            });
        },

        createPercentageCharts() {
            // Create Pie Charts for each tier showing percentage change
            this.tiers.forEach((tierValue, tierIndex) => {
                const percentageChart = document.getElementById('percentageChart_' + tierValue);
                if (percentageChart) {
                    new Chart(percentageChart, {
                        type: 'pie',
                        data: {
                            labels: this.years.slice(1), // Use years except the first one (for comparison)
                            datasets: [{
                                label: 'Percentage Change',
                                data: this.percentageData[tierIndex], // Percentage changes for this tier
                                backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)','rgba(0, 0, 255, 0.6)'], // Custom colors
                                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
                                borderWidth: 1,
                            }],
                        },
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            return tooltipItem.raw.toFixed(2) + '%'; // Format percentage
                                        },
                                    },
                                },
                            },
                        },
                    });
                }
            });
        },
    },
};
</script>


<style scoped>
.pie-charts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.pie-chart-item {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pie-chart-item canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>